export function decodeUserDetails(token: string): {
  _id: string;
  role: 'user' | 'admin';
  verified: boolean;
  iat: number;
  exp: number;
} {
  let userDetails;
  try {
    const base64 = token.split('.')[1];
    const decodedDetails = JSON.parse(atob(base64));
    userDetails = decodedDetails;
  } catch (e) {
    console.error(e);
  }
  return userDetails || null;
}

export const emailRegexPattern =
  '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{1,6}$';
