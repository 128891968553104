import { ChangeDetectorRef, Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'lang-drop',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class CustomDropdownComponent {
  currentLang = 'eng';
  displayLang = '';

  arrayInd = new Map<string, { display: string }>([
    ['en', { display: 'English' }],
    ['prt', { display: 'Portugese' }],
    ['sp', { display: 'Spanish' }],
  ]);
  constructor(
    public transLocoService: TranslocoService // private cd: ChangeDetectorRef
  ) {
    this.toggleLangText();
  }

  toggleLangText() {
    this.currentLang = this.transLocoService.getActiveLang();
    this.displayLang = this.returnArr(this.currentLang) as string;
  }
  returnArr(lang: any) {
    return this.arrayInd.get(lang)?.display;
  }

  changeLang(lang: string): void {
    this.transLocoService.setActiveLang(lang);
    this.toggleLangText();
    if (localStorage.getItem('lang') == ' ') {
      localStorage.setItem('lang', lang);
    }
  }
}
