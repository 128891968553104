import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  baseUrl = environment.BASE_URL;

  constructor(private httpClient: HttpClient) {}

  uploadDocument(files: any, token: string) {
    let headers = new HttpHeaders();
    headers = headers.set('authorization', `${token}`);
    const formData = new FormData();

    // Append each file to the FormData object
    for (const file of files) {
      formData.append('images', file);
    }

    return this.httpClient.post(this.baseUrl + '/document/upload', files, {
      headers,
    });
  }

  deleteDocument(keyName: any, token: string) {
    let headers = new HttpHeaders();
    headers = headers.set('authorization', `${token}`);
    const _keyName = {
      keyName: keyName,
    };
    return this.httpClient.post(this.baseUrl + '/document/delete', _keyName, {
      headers,
    });
  }
}
