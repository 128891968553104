<div class="logout-modal-container">
  <h4>
    {{ "all.unsave" | transloco }}
  </h4>
  <p>
    {{ "u.sure" | transloco }}
  </p>
  <div class="button-container">
    <ion-button (click)="close()" fill="outline">
      {{ "cancel" | transloco }}</ion-button
    >
    <ion-button (click)="logout()" fill="solid">
      {{ "auth.logout" | transloco }}
    </ion-button>
  </div>
</div>
