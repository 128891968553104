import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss'],
})
export class LogoutModalComponent implements OnInit {
  constructor(public modalController: ModalController) {}

  ngOnInit() {}

  close() {
    this.modalController.dismiss();
  }
  logout() {
    const onClosedData = {
      isLogout: true,
    };
    this.modalController.dismiss(onClosedData);
  }
}
