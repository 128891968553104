import { createFeatureSelector, createSelector } from '@ngrx/store';
import { KYC } from '../reducer/kyc.reducer';

export const _selectKYC = createFeatureSelector<KYC>('kyc');
export const _selectKYCState = createSelector(_selectKYC, (state) => state);

export const companyDetails = createSelector(
  _selectKYCState,
  (state) => state.companyDetails
);

export const stepsCompleted = createSelector(
  _selectKYCState,
  (state) => state.stepsCompleted
);
export const userKycPreviewIsEdit = createSelector(
  _selectKYCState,
  (state) => state.userKycPreviewIsEdit
)
export const addressForm = createSelector(
  _selectKYCState,
  (state) => state.officeAddress
);
export const contactForm = createSelector(
  _selectKYCState,
  (state) => state.contactForm
);
export const directorsForm = createSelector(
  _selectKYCState,
  (state) => state.directorsForm
);
export const productInfoForm = createSelector(
  _selectKYCState,
  (state) => state.productInfoForm
);
export const opInfoForm = createSelector(
  _selectKYCState,
  (state) => state.opInfoForm
);
export const kycUserInfo = createSelector(
  _selectKYCState,
  (state) => state.kycUserInfo
);
export const kycDocumentsForm = createSelector(
  _selectKYCState,
  (state) => state.kycDocumentsForm
);

export const bankDetailsForm = createSelector(
  _selectKYCState,
  (state) => state.bankDetailsForm
);
export const shareHolderForm = createSelector(
  _selectKYCState,
  (state) => state.shareHolderForm
);