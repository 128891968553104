import { Action, createReducer, on } from '@ngrx/store';

import {
  setStepsCompleted,
  setCompanyDetails,
  setAddressForm,
  setContactForm,
  setDirectorsForm,
  setProductInfoForm,
  setKycDocumentsForm,
  setOpInfoForm,
  setBankDetailsForm,
  setShareHolderForm,
  setUserKycPreviewIsEdit,
} from '../action/kyc.action';
import { setkycUserInfo } from '../action/user.action';

export interface UploadedS3File {
  url: string;
  type: string;
  userId: string;
  ts: string;
  key: string;
}

export interface KYC {
  stepsCompleted: number;
  userKycPreviewIsEdit: boolean;
  companyDetails: {
    companyName: string;
    companyId: string;
    countryOfIncorporation: string;
    countryOfOperation: string;
    typeOfBuinessActivity: string;
    legalRegulatoryBody: string;
    legalStructure: string;
    lengthOfEstablishment: string;
    branches: any[];
    abroadBranches: string
  } | null;
  officeAddress: {
    buildingName: string;
    officeSuiteNumber: string;
    street: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    t1: string;
    t2: string;
    t3: string;
  } | null;
  contactForm: {
    countryOfResidence: string;
    firstName: string;
    jobTitle: string;
    lastName: string;
    salutation: string;
    isShareholder: boolean;
  } | null;
  kycUserInfo: {
    contactNumber: string;
    email: string;
    userAction: string;
    profileStatus: string;
    companyName: string;
  } | null;
  directorsForm: {
    numberOfDirectors: string;
    directorDetails: [
      {
        firstName: string;
        lastName: string;
        nationality: string;
        countryOfResidence: string;
        identityDocument: UploadedS3File;
        proofOfAddress: UploadedS3File;
      }
    ];
  } | null;
  productInfoForm: {
    goodsClassification: string;
    expectedPurity: string;
    sgsMitraReport: UploadedS3File;
    areGoodsSourcedFromMultipleEntities: boolean;
  } | null;
  kycDocumentsForm: {
    tradeLicense: UploadedS3File;
    revenueLetter: UploadedS3File;
    previousBillOfLading: UploadedS3File;
    exportLicense: UploadedS3File;
    bankDetails: {
      accountName: string;
      IBAN: string;
      SWIFT: string;
      branch: string;
      bankName: string;
    };
  } | null;
  bankDetailsForm: {
      accountName: string;
      accountNumber: string;
      IBAN: string;
      SWIFT: string;
      branch: string;
      bankName: string;
      officerName: string;
      officerPhone: string;
      officerEmail: string;
  } | null;
  shareHolderForm: {
    numberOfShareHolders: string;
    shareHolderDetails: [
      {
        firstName: string;
        lastName: string;
        nationality: string;
        countryOfResidence: string;
        identityDocument: UploadedS3File;
        proofOfAddress: UploadedS3File;
      }
    ]
} | null;
  opInfoForm: {
    ownCrusher: number;
    crusherCertified: number;
    crusherLicense: UploadedS3File;
    productionCapacity: string;
    miningLicense: UploadedS3File;
    municipalLicense: UploadedS3File;
    environmentalLicense: UploadedS3File;
  } | null;
}
export const initialStateKYC: KYC = {
  stepsCompleted: 0,
  userKycPreviewIsEdit: false,
  companyDetails: null,
  officeAddress: null,
  contactForm: null,
  kycUserInfo: null,
  directorsForm: null,
  productInfoForm: null,
  kycDocumentsForm: null,
  bankDetailsForm: null,
  opInfoForm: null,
  shareHolderForm: null
};

const reducer = createReducer(
  initialStateKYC,

  on(setStepsCompleted, (state, { stepsCompleted }) => ({
    ...state,
    stepsCompleted: stepsCompleted,
  })),
  on(setUserKycPreviewIsEdit, (state, { userKycPreviewIsEdit }) => ({
    ...state,
    userKycPreviewIsEdit: userKycPreviewIsEdit,
  })),
  on(setCompanyDetails, (state, { companyDetails }) => ({
    ...state,
    companyDetails: companyDetails,
  })),
  on(setAddressForm, (state, { officeAddress }) => ({
    ...state,
    officeAddress: officeAddress,
  })),
  on(setContactForm, (state, { contactForm }) => ({
    ...state,
    contactForm: contactForm,
  })),
  on(setkycUserInfo, (state, { kycUserInfo }) => ({
    ...state,
    kycUserInfo: kycUserInfo,
  })),
  on(setShareHolderForm, (state , { shareHolderForm }) => ({
    ...state,
    shareHolderForm: shareHolderForm,
  })),
  on(setDirectorsForm, (state, { directorsForm }) => ({
    ...state,
    directorsForm: directorsForm,
  })),
  on(setProductInfoForm, (state, { productInfoForm }) => ({
    ...state,
    productInfoForm: productInfoForm,
  })),
  on(setKycDocumentsForm, (state, { kycDocumentsForm }) => ({
    ...state,
    kycDocumentsForm: kycDocumentsForm,
  })),
  on(setBankDetailsForm, (state, { bankDetailsForm }) => ({
    ...state,
    bankDetailsForm: bankDetailsForm,
  })),
  on(setOpInfoForm, (state, { opInfoForm }) => ({
    ...state,
    opInfoForm: opInfoForm,
  }))
);
/**
 * gets globalReducer
 * @param state global state
 * @param action global action
 * @returns reducer state and action
 */
export const kycReducer = (state: KYC | undefined, action: Action) => {
  return reducer(state, action);
};
