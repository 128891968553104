import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createAction, select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { Router } from '@angular/router';
import { ToastService } from 'src/app/shared/toast.service';
import {
  setGlobalEmail,
  setUser,
  setkycUserInfo,
} from 'src/app/store/action/user.action';
import {
  getUserInfo,
  setAddressForm,
  setCompanyDetails,
  setStepsCompleted,
  setContactForm,
  setDirectorsForm,
  setKycDocumentsForm,
  setOpInfoForm,
  setProductInfoForm,
  setBankDetailsForm,
  setShareHolderForm,
} from '../action/kyc.action';
import { KYCService } from 'src/app/pages/kyc/kyc.service';
import { userKycPreviewIsEdit } from '../selectors/kyc.selector';

export const noAction = createAction('[Campaigns] No Action ');
@Injectable({
  providedIn: 'root',
})
export class KYCStoreEffects {
  getKycInfpo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getUserInfo),
        withLatestFrom(this.store.pipe(select(userKycPreviewIsEdit))), // Combine with the edit flag
        switchMap(([data, userKycPreviewIsEditVal]) => {
          if (!data.token) {
            console.error('TOKEN');
          }


          let params = null;
          if(data && data.additionalParams && data.additionalParams.isAdmin) {
            params = {
              isAdmin: data.additionalParams.isAdmin,
              userId: data.additionalParams.userId
            }
          }else {
            params = null;
          }
          this.kycService.getInfo(data.token as string , params).subscribe({
            next: (res: any) => {
              if (res.code === 200) {
                this.store.dispatch(
                  setCompanyDetails({ companyDetails: res.data.companyDetails })
                );
                this.store.dispatch(
                  setAddressForm({ officeAddress: res.data.officeAddress })
                );

                this.store.dispatch(
                  setContactForm({ contactForm: res.data.contactForm })
                );

                this.store.dispatch(
                  setShareHolderForm({ shareHolderForm: res.data.shareholderForm })
                );

                this.store.dispatch(
                  setDirectorsForm({ directorsForm: res.data.directorsForm })
                );
                this.store.dispatch(
                  setProductInfoForm({
                    productInfoForm: res.data.productInfoForm,
                  })
                );
                this.store.dispatch(
                  setKycDocumentsForm({
                    kycDocumentsForm: res.data.kycDocumentsForm,
                  })
                );
                this.store.dispatch(
                  setBankDetailsForm({
                    bankDetailsForm: res.data.bankDetailsForm,
                  })
                );
                this.store.dispatch(
                  setOpInfoForm({
                    opInfoForm: res.data.opInfoForm,
                  })
                );

                const {
                  companyName,
                  contactNumber,
                  email,
                  userAction,
                  profileStatus,
                } = res.data;

                this.store.dispatch(
                  setkycUserInfo({
                    kycUserInfo: {
                      contactNumber,
                      email,
                      userAction,
                      profileStatus,
                      companyName,
                    },
                  })
                );

                if(!userKycPreviewIsEditVal) {
                  this.store.dispatch(
                    setStepsCompleted({ stepsCompleted: res.data.stepsCompleted })
                  );
                }
              }
            },
            error: (err: any) => {
              if (err.code == 400) {
              }
              return of(noAction());
            },
          });
          return of(noAction());
        })
      ),
    { dispatch: false }
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private router: Router,
    private toastService: ToastService,
    private kycService: KYCService
  ) {}
}
