import { createAction, props } from '@ngrx/store';

export const setStepsCompleted = createAction(
  '[KYC] Set Steps',
  props<{ stepsCompleted: number }>()
);

export const setUserKycPreviewIsEdit = createAction(
  '[KYC] Set Kyc Preview User IsEdit',
  props<{ userKycPreviewIsEdit: boolean }>()
)

export const setCompanyDetails = createAction(
  '[KYC] Set companyDetails',
  props<{ companyDetails: any }>()
);
export const setAddressForm = createAction(
  '[KYC] Set officeAddress',
  props<{ officeAddress: any }>()
);
export const setContactForm = createAction(
  '[KYC] Set contactForm',
  props<{ contactForm: any }>()
);
export const setDirectorsForm = createAction(
  '[KYC] Set directorsForm',
  props<{ directorsForm: any }>()
);
export const setShareHolderForm = createAction(
  '[KYC] Set shareHolderForm',
  props<{ shareHolderForm: any }>()
);
export const setProductInfoForm = createAction(
  '[KYC] Set productInfoForm',
  props<{ productInfoForm: any }>()
);
export const setKycDocumentsForm = createAction(
  '[KYC] Set kycDocumentsForm',
  props<{ kycDocumentsForm: any }>()
);

export const setBankDetailsForm = createAction(
  '[KYC] Set BankDetailsForm',
  props<{ bankDetailsForm: any }>()
);

export const setOpInfoForm = createAction(
  '[KYC] Set opInfoForm',
  props<{ opInfoForm: any }>()
);

export const getUserInfo = createAction(
  '[KYC] Get UserInfo',
  props<{ token: string , additionalParams?: any }>()
);
