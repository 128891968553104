import { ActionReducerMap } from '@ngrx/store';
import { languageReducer, Language } from './language.reducer';
import { userReducer, UserToken } from './user.reducer';
import { KYC, kycReducer } from './kyc.reducer';

export interface AppState {
  language: Language;
  user: UserToken;
  kyc: KYC;
}

/**
 * app module reducer
 */
export const appReducers: ActionReducerMap<AppState> = {
  language: languageReducer,
  user: userReducer,
  kyc: kycReducer,
};
