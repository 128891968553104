<ng-content></ng-content>
<a class="type-toggle" (click)="toggleShow()">
  <ion-icon
    class="show-option"
    name="eye-off-outline"
    *ngIf="!showPassword"
  ></ion-icon>
  <ion-icon
    class="hide-option"
    *ngIf="showPassword"
    name="eye-outline"
  ></ion-icon>
</a>
