import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastService } from './shared/toast.service';
import { LogOutService } from './logout.service';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    public router: Router,
    private toastService: ToastService,
    private logOutService: LogOutService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error.status == 401) {
        }
        if (error.status == 410) {
        }

        if(error.status === 0) {
          return throwError(() => {
            this.toastService.presentToast(
              'bottom',
              "There was error processing your request. Please try again later",
              'error'
            );
          });
        }

        return throwError(() => {
          this.toastService.presentToast(
            'bottom',
            error.message || error.error.message,
            'error'
          );
        });

      })
    );
  }
}
