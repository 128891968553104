import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileNameCleanup',
})
export class FileNameCleanupPipe implements PipeTransform {
  transform(value: string): string {
    // Regular expression to match and remove the unwanted text from the filename
    const regex = /-\w{16,}(?=\.[^.]*$)/g; // Match any alphanumeric text of 16 or more characters preceded by a dash and followed by '.pdf'

    // Use the replace() method to remove the unwanted text from the filename
    return value?.replace(regex, '');
  }
}
