import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { LogoutModalComponent } from './logout-modal/logout-modal.component';

@Injectable({
  providedIn: 'root',
})
export class LogOutService {
  constructor(
    private modalController: ModalController,
    private storage: Storage,
    private router: Router
  ) {}
  async openLogoutModal() {
    const modal = await this.modalController.create({
      component: LogoutModalComponent,
      cssClass: 'logout-modal-container',
    });
    modal.onDidDismiss().then((modelData: any) => {
      if (modelData.data?.isLogout) {
        this.removeUser();
      }
    });
    return await modal.present();
  }

  removeUser() {
    this.storage.remove('state').then((data) => {
      this.navigateAndReload();
    });
  }

  navigateAndReload() {
    this.router.navigate(['/auth']).then(() => {
      window.location.reload();
    });
  }
}
