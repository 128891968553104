<div class="dropdown-container">
  <ion-icon name="earth-outline"></ion-icon>
  <ion-select
    (ngModelChange)="changeLang($event)"
    [(ngModel)]="currentLang"
    interface="popover"
    [placeholder]=""
  >
    <ion-select-option value="en">
      <div class="lang">
        <div class="desc">English</div>
      </div>
    </ion-select-option>
    <ion-select-option value="prt">
      <div class="lang">
        <div class="desc">Português</div>
      </div>
    </ion-select-option>
  </ion-select>
</div>
