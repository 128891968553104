import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastController: ToastController) {}

  async presentToast(
    position: 'top' | 'middle' | 'bottom',
    msg: string,
    type: 'success' | 'warn' | 'error',
    icon?: string
  ) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 5500,
      position: position,
      cssClass: `netz-toast-${type}`,
      icon: icon ?? '',
    });

    await toast.present();
  }
}
