import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserToken } from '../reducer/user.reducer';

export const _selectGlobalState = createFeatureSelector<UserToken>('user');
export const GlobalState = createSelector(_selectGlobalState, (state) => state);

export const activeAuthToken = createSelector(
  _selectGlobalState,
  (state) => state.token
);

export const isLoggedIn = createSelector(
  _selectGlobalState,
  (state) => state.isLoggedIn
);
export const userId = createSelector(
  _selectGlobalState,
  (state) => state.userId
);
export const role = createSelector(_selectGlobalState, (state) => state.role);
export const globalUserEmail = createSelector(
  _selectGlobalState,
  (state) => state.email
);
