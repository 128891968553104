import { Action, createReducer, on } from '@ngrx/store';
import { setLanguage } from '../action/global.action';

export interface Language {
  lang: { id: string; label: string };
}

/**
 * initialState
 */
const initialState: Language = {
  lang: {
    id: 'en',
    label: 'English'
  }
};

const reducer = createReducer(
  initialState,
  on(setLanguage, (state, { lang }) => ({
    ...state,
    lang
  }))
);
/**
 * gets globalReducer
 * @param state global state
 * @param action global action
 * @returns reducer state and action
 */
export const languageReducer = (
  state: Language | undefined,
  action: Action
) => {
  return reducer(state, action);
};
