<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancelChanges()">{{
        "cancel" | transloco
      }}</ion-button>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
    <!-- <ion-buttons slot="end">
      <ion-button (click)="confirmChanges(null)">Done</ion-button>
    </ion-buttons> -->
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar (ionInput)="searchbarInput($event)"></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content color="light" class="ion-padding">
  <ion-list id="modal-list" [inset]="true">
    <ion-item (click)="confirmChanges(no)" *ngFor="let no of filteredList">
      <ion-label> {{ no.flag }} ({{ no.dial_code }}) {{ no.name }}</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
