import { Component, ContentChild, OnInit } from '@angular/core';
import { IonInput } from '@ionic/angular';

@Component({
  selector: 'app-hide-show-password',
  templateUrl: './hide-show-password.component.html',
  styleUrls: ['./hide-show-password.component.scss'],
})
export class HideShowPasswordComponent {
  showPassword = false;
  @ContentChild(IonInput) input!: IonInput;
  constructor() {}
  toggleShow() {
    this.showPassword = !this.showPassword;
    this.input.type = this.showPassword ? 'text' : 'password';
  }
}
