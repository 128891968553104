import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { _regReducer } from '.';
import { RegStoreEffects } from './effects/home-page.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('registrationModule', _regReducer),
    EffectsModule.forFeature([RegStoreEffects]),
  ],
  exports: [],
  providers: [RegStoreEffects],
})
export class RegStoreModule {}
