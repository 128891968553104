import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RegModuleState } from '..';

import { RegState } from '../reducers/home-page.reducer';

export const fromRegModule =
  createFeatureSelector<RegModuleState>('registrationModule');

export const getRegState = createSelector(
  fromRegModule,
  (state: RegModuleState) => state.registration
);

export const getRegEmail = createSelector(
  getRegState,
  (state: RegState) => state.email
);
export const getRegPhone = createSelector(
  getRegState,
  (state: RegState) => state.phone
);
export const getRegToken = createSelector(
  getRegState,
  (state: RegState) => state.token
);
export const getCurrentRegStep = createSelector(
  getRegState,
  (state: RegState) => state.currentStep
);
export const getWrongOTPFlag = createSelector(
  getRegState,
  (state: RegState) => state.emailWrongOTP
);
export const getMobileWrongOTP = createSelector(
  getRegState,
  (state: RegState) => state.mobileWrongOTP
);
export const getLoginState = createSelector(
  getRegState,
  (state: RegState) => state.userLogin
);
