import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { isLoggedIn } from '../store/selectors/user.selector';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate, CanActivateChild {
  auth = false;
  constructor(private router: Router, private store: Store) {
    this.store.select(isLoggedIn).subscribe((data) => {
      this.auth = data;
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.auth) {
      return this.router.parseUrl('/auth/login');
    }
    // Your authentication logic goes here
    // Return true if the user is authenticated or redirect to a login page
    return true;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.auth) {
      return this.router.parseUrl('/auth/login');
    }
    // Your authentication logic for child routes goes here
    // Return true if the user is authenticated or redirect to a login page
    return true;
  }
}
