import { createAction, props } from '@ngrx/store';

export const setRegToken = createAction(
  '[REG] Set TOKEN',
  props<{ token: string }>()
);

export const setEmail = createAction(
  '[REG] Set Email',
  props<{ email: string }>()
);

export const setPhone = createAction(
  '[REG] Set Phone',
  props<{ phone: string }>()
);

export const regEmail = createAction(
  '[REG] EMAIL ',
  props<{ password: string; email: string }>()
);
export const setRegStep = createAction(
  '[REG] STEP CURRENT ',
  props<{ currentStep: string }>()
);

export const regVerifyEmailOtp = createAction(
  '[REG] VERIFY EMAIL OTP ',
  props<{ otp: string; forgotPassword: boolean }>()
);

export const sentPhoneOtp = createAction(
  '[REG] SENT PHONE OTP ',
  props<{ contactNumber: string }>()
);
export const setEmailWrongOtp = createAction(
  '[REG] SET EMAIL WRONG OTP ',
  props<{ emailWrongOTP: boolean }>()
);
export const setMobileWrongOtp = createAction(
  '[REG] SET MOBILE WRONG OTP ',
  props<{ mobileWrongOTP: boolean }>()
);

export const verifyPhoneOtp = createAction(
  '[REG] Verify PHONE OTP ',
  props<{ otp: string }>()
);
export const resendOtpEmail = createAction('[REG] Resend Email OTP ');

export const resetUser = createAction(
  '[REG] RESET Email',
  props<{ email: string }>()
);

export const updateNewPassword = createAction(
  '[REG] RESET USER PASSWORD ',
  props<{ password: string }>()
);
export const userLogin = createAction(
  '[REG] USER LOGIN ',
  props<{ password: string; email: string }>()
);
export const adminLogin = createAction(
  '[REG] ADMIN LOGIN ',
  props<{ password: string; email: string }>()
);
export const setLoginState = createAction(
  '[REG] USER LOGIN STATE',
  props<{ userLogin: boolean }>()
);
export const resetReg = createAction('[REG] RESET');
