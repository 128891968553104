import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileUploadService } from './file-upload.service';
import { Store } from '@ngrx/store';
import { getRegToken } from 'src/app/pages/register/store_register/selectors/home-page.selector';
import { ToastService } from '../toast.service';
import { AbstractControl, FormControl } from '@angular/forms';
import { KYCService } from 'src/app/pages/kyc/kyc.service';
import { Browser } from '@capacitor/browser';
import { take, takeUntil } from 'rxjs';
const SUPPORTED_FILE_TYPES = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/pdf',
];

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  @Output() uploadedFiles = new EventEmitter<any>();
  @Input() isMultiple: boolean = true;

  @Input() token!: string;
  @Input() formValue: any;

  files: any[] = [];

  constructor(
    private fileUploadService: FileUploadService,
    private toastService: ToastService,
    private kYCService: KYCService
  ) { }

  onFileDropped($event: any) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(event: any) {
    const files = event.target.files;
    this.prepareFilesList(files);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  uploadFilesSimulator(index: number) {
    if (index === this.files.length) {
      return;
    }

    const file = this.files[index];

    const formData = new FormData();
    formData.append('images', file);

    const token = this.token; // Replace with your actual token

    this.fileUploadService.uploadDocument(formData, token).subscribe(
      (response: any) => {
        // Handle successful upload
        if (response.code == 200 && response.status == true) {
          file.progress = 100;
          this.uploadedFiles.emit(...response.data); // Emit the response
          this.uploadFilesSimulator(index + 1);
        }
      },
      (error) => {
        // Handle error
        this.toastService.presentToast('bottom', 'Error', 'error');
      }
    );
  }

  prepareFilesList(files: Array<any>) {
    if (!this.isMultiple) {
      this.files = [];
    }

    for (const item of files) {
      const fileSizeInMB = item.size / (1024 * 1024); // Convert size to MB

      if (fileSizeInMB <= 5) {
        if (SUPPORTED_FILE_TYPES.includes(item.type)) {
          item.progress = 0;
          this.files.push(item);
        } else {
          this.toastService.presentToast(
            'bottom',
            'Unsupported file type. Only JPG,JPEG, PNG and PDF files are allowed.',
            'error'
          );
        }
      } else {
        this.toastService.presentToast(
          'bottom',
          'File size exceeds 5MB',
          'error'
        );
      }
    }

    this.uploadFilesSimulator(0);
  }

  formatBytes(bytes: any, decimals?: any) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  getFile(data: {
    key: string;
    ts: string;
    type: string;
    url: string;
    userId: string;
  }) {
    const key = new URL(data.url);
    this.kYCService
      .getPresignedFile(key.pathname.replace('/', '').trim(), this.token)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.data) {
          this.openCapacitorSite(res.data);
        }
      });
  }

  openCapacitorSite = async (url: string) => {
    await Browser.open({ url: url });
  };

  deleteFileApi(
    data: {
      key: string;
      ts: string;
      type: string;
      url: string;
      userId: string;
    },
    i: any
  ) {
    if (!data?.key) {
      this.deleteFile(i);
      this.uploadedFiles.emit(null);
      this.removeFileFromFormValue(i); // Remove file from formValue
      return;
    }

    this.fileUploadService.deleteDocument(data.key, this.token).subscribe(
      (res) => {
        this.deleteFile(i);
        this.uploadedFiles.emit(null);
        this.removeFileFromFormValue(i); // Remove file from formValue
        this.files = [];
      },
      (err) => {
        console.error(err);
      }
    );
  }

  removeFileFromFormValue(index: number) {
    if (this.formValue && Array.isArray(this.formValue)) {
      // If formValue is an array
      this.formValue.splice(index, 1);
    } else {
      // If formValue is not an array
      this.formValue = null;
    }
  }


}
