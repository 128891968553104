import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  baseUrl = environment.BASE_URL;

  constructor(private httpClient: HttpClient) {}
  createUser(createUser: { email: string; password: string }) {
    const params = new HttpParams()
      .set('email', createUser.email)
      .set('password', createUser.password);

    return this.httpClient.post<{
      status: string;
      code: number;
      data: {
        token: string;
      };
    }>(this.baseUrl + '/user/create', createUser, {
      params,
    });
  }
  userCheck(email: string) {
    const params = new HttpParams().set('email', email);

    return this.httpClient.get<{
      status: boolean;
      code: number;
      data: { accountExists: boolean };
    }>(this.baseUrl + '/user/check', {
      params,
    });
  }
  mobileNumberCheck(mobile: string, token: string) {
    const params = new HttpParams().set('contactNumber', mobile);
    let headers = new HttpHeaders();
    headers = headers.set('authorization', `${token}`);

    return this.httpClient.get<{
      status: boolean;
      code: number;
      data: { phoneNumberExists: boolean };
    }>(this.baseUrl + '/user/phone/check', {
      params,
      headers,
    });
  }

  emailOTPVerify(
    { otp, forgotPassword }: { otp: string; forgotPassword: boolean },
    token: string
  ) {
    const params = new HttpParams().set('forgotPassword', forgotPassword);

    let headers = new HttpHeaders();
    headers = headers.set('authorization', `${token}`);

    return this.httpClient.post<{
      status: string;
      code: number;
      data: {
        emailVerification: string;
      };
    }>(
      this.baseUrl + '/user/email/otp/verify',
      { otp: otp },
      {
        params,
        headers,
      }
    );
  }
  resendEmailOTPVerify(token: string) {
    let headers = new HttpHeaders();
    headers = headers.set('authorization', `${token}`);

    return this.httpClient.post<{
      status: string;
      code: number;
      data: {
        emailOtpTriggered: boolean;
      };
    }>(
      this.baseUrl + '/user/email/otp/resend',
      {},
      {
        headers,
      }
    );
  }

  sentPhoneOTP(contactNumber: string, token: string) {
    let headers = new HttpHeaders();
    headers = headers.set('authorization', `${token}`);

    return this.httpClient.post<{
      status: string;
      code: number;
      data: {
        emailVerification: string;
      };
    }>(
      this.baseUrl + '/user/phone/otp',
      { contactNumber },
      {
        headers,
      }
    );
  }

  verifyPhoneOTP(otp: string, token: string) {
    let headers = new HttpHeaders();
    headers = headers.set('authorization', `${token}`);

    return this.httpClient.post<{
      status: string;
      code: number;
      data: {
        emailVerification: string;
      };
    }>(
      this.baseUrl + '/user/phone/otp/verify',
      { otp },
      {
        headers,
      }
    );
  }
  resetUser(email: string) {
    const params = new HttpParams().set('email', email);

    return this.httpClient.post(this.baseUrl + '/user/reset', {}, { params });
  }

  updateNewPassword(password: string, token: string) {
    let headers = new HttpHeaders();
    headers = headers.set('authorization', `${token}`);

    return this.httpClient.post<{
      status: string;
      code: number;
      data: {
        token: string;
      };
    }>(
      this.baseUrl + '/user/reset/password',
      { password },
      {
        headers,
      }
    );
  }
  userLogin({ email, password }: { email: string; password: string }) {
    const params = new HttpParams()
      .set('email', email)
      .set('password', password);

    return this.httpClient.post<{
      status: string;
      code: number;
      data: {
        token: string;
        emailVerification: string;
        phoneVerification: string;
      };
    }>(
      this.baseUrl + '/user/login',
      { email, password },
      {
        params,
      }
    );
  }
  adminLogin({ email, password }: { email: string; password: string }) {
    const params = new HttpParams()
      .set('email', email)
      .set('password', password);

    return this.httpClient.post<{
      status: string;
      code: number;
      data: {
        token: string;
        emailVerification: string;
        phoneVerification: string;
      };
    }>(
      this.baseUrl + '/admin/login',
      { email, password },
      {
        params,
      }
    );
  }
}
