import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-file-progress',
  templateUrl: './upload-file-progress.component.html',
  styleUrls: ['./upload-file-progress.component.scss'],
})
export class UploadFileProgressComponent implements OnInit {
  @Input() progress = 0;
  constructor() {}

  ngOnInit() {}
}
