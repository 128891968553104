<ion-app>
  <ion-header [translucent]="true">
    <ion-toolbar class="header-web">
      <lang-drop></lang-drop>
      <ion-title>
        <img style="width: 7rem" src="./assets/netz-n.svg" />
      </ion-title>
      <span
        slot="end"
        class="tablet-button"
        class="dont-have-tablet"
        *ngIf="($isLoggedIn | async) === false"
        style="font-weight: 500; cursor: pointer"
        (click)="currentUrl === '/register/account' ? navTOLogin() : navTOReg()"
      >
        <span *ngIf="currentUrl === '/auth/login'">
          {{ "signup.d" | transloco }}
        </span>
        <span *ngIf="currentUrl === '/register/account'">
          {{ "already.have.acc" | transloco }}
        </span>
      </span>
      <div
        slot="end"
        class="tablet-button"
        class="email"
        *ngIf="($isLoggedIn | async) === true && email"
      >
        {{ email }}
      </div>
      <div
        (click)="LogOut()"
        slot="end"
        class="tablet-button"
        class="button"
        *ngIf="$isLoggedIn | async"
        style="cursor: pointer"
      >
        <ion-icon name="power-outline"></ion-icon>
        {{ "auth.logout" | transloco }}
      </div>
    </ion-toolbar>
  </ion-header>
  <ion-router-outlet> </ion-router-outlet>
</ion-app>
