import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HideShowPasswordComponent } from './hide-show-password/hide-show-password.component';
import { IonicModule } from '@ionic/angular';
import { CountryTypeaheadComponent } from './country-typeahead/country-typeahead.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { UploadFileProgressComponent } from './file-upload/upload-file-progress/upload-file-progress.component';
import { FileUploadService } from './file-upload/file-upload.service';
import { FileNameCleanupPipe } from '../pages/admin/fileNameCleanup.pipe';
import { TranslocoModule } from '@ngneat/transloco';

const SharedComponent = [
  CountryTypeaheadComponent,
  HideShowPasswordComponent,
  FileUploadComponent,
  UploadFileProgressComponent,
  FileNameCleanupPipe,
];
@NgModule({
  imports: [CommonModule, IonicModule, TranslocoModule],
  declarations: [...SharedComponent],
  exports: [...SharedComponent],
  providers: [FileUploadService],
})
export class SharedModule {}
