import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { appReducers } from './reducer/app.reducer';
import { EffectsModule } from '@ngrx/effects';
import { KYCStoreEffects } from './effects/kyc.effects';
import { RegStoreModule } from '../pages/register/store_register/home-page.store.module';

@NgModule({
  imports: [
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot([KYCStoreEffects]),
    RegStoreModule,
  ],
  exports: [StoreModule],
})
export class AppStoreModule {}
