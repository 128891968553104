<div class="files-list" *ngIf="formValue">
  <div class="single-file">
    <div
      class="file-icon"
      style="width: 50px; display: flex; cursor: pointer"
      (click)="getFile(formValue)"
    >
      <img src="./assets/file.png" alt="" />
    </div>
    <div class="info" style="cursor: pointer">
      <h4 class="name" (click)="getFile(formValue)">
        {{ formValue?.key | fileNameCleanup }}
      </h4>
    </div>
    <div class="delete" (click)="deleteFileApi(formValue, 0)">
      <img width="28" src="./assets/Cancel.svg" alt="" />
    </div>
  </div>
</div>
<div
  *ngIf="!formValue"
  class="container"
  appDnd
  (fileDropped)="onFileDropped($event)"
>
  <input
    type="file"
    #fileDropRef
    id="fileDropRef"
    [multiple]="isMultiple"
    (change)="fileBrowseHandler($event)"
  />
  <img src="./assets/file-upload.png" alt="" />
  <h3>
    {{ "drag.here" | transloco }}
    <a for="fileDropRef">{{ "browse" | transloco }}</a>
    {{ "to.upload" | transloco }}
  </h3>
</div>
<div class="files-list" *ngIf="!formValue">
  <div class="single-file" *ngFor="let file of files; let i = index">
    <div class="file-icon" style="width: 50px">
      <img src="./assets/file-upload.png" alt="" />
    </div>
    <div class="info">
      <h4 class="name">
        {{ file?.name }}
      </h4>
      <p class="size">
        {{ formatBytes(file?.size) }}
      </p>
      <app-upload-file-progress
        [progress]="file?.progress"
      ></app-upload-file-progress>
    </div>

    <div class="delete" (click)="deleteFileApi(formValue, i)">
      <img width="28" src="./assets/Cancel.svg" alt="" />
    </div>
  </div>
</div>
