import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TelePhone, tel } from 'src/app/helpers/tel';
export interface Item {
  text: string;
  value: string;
}

@Component({
  selector: 'country-typeahead',
  templateUrl: './country-typeahead.component.html',
  styleUrls: ['./country-typeahead.component.scss'],
})
export class CountryTypeaheadComponent implements OnInit {
  @Input() items: TelePhone[] = [];
  @Input() selectedItems: string[] = [];
  @Input() title = 'Select Items';
  telephone = tel;

  @Output() selectionCancel = new EventEmitter<void>();
  @Output() selectionChange = new EventEmitter<TelePhone | null>();
  filteredList: TelePhone[] = this.telephone;

  // filteredItems: TelePhone[] = [];
  // workingSelectedValues: string[] = [];

  ngOnInit() {
    // this.filteredItems = [...this.items];
    // this.workingSelectedValues = [...this.selectedItems];
  }

  trackItems(index: number, item: any) {
    return item.no;
  }

  cancelChanges() {
    this.selectionCancel.emit();
  }

  confirmChanges(number: any) {
    this.selectionChange.emit(number);
  }

  searchbarInput(ev: any) {
    this.filterList(ev.target.value);
  }

  /**
   * Update the rendered view with
   * the provided search query. If no
   * query is provided, all data
   * will be rendered.
   */
  filterList(searchQuery: string | undefined) {
    const normalizedQuery = searchQuery?.toLowerCase() || '';
    this.filteredList = this.telephone.filter((item) => {
      return item.name.toLowerCase().includes(normalizedQuery);
    });
  }
}
