import { Action, createReducer, on } from '@ngrx/store';
import { setGlobalEmail, setUser, setUserState } from '../action/user.action';
import { decodeUserDetails } from 'src/app/helpers/auth.helpers';

export interface UserToken {
  token: string | null;
  isLoggedIn: boolean;
  userId: string | null;
  role: 'user' | 'admin';
  email: string;
}
const initialState: UserToken = {
  token: null,
  isLoggedIn: false,
  userId: null,
  role: 'user',
  email: '',
};

const reducer = createReducer(
  initialState,
  on(setUser, (state, { token }) => ({
    ...(state as any),
    token,
    isLoggedIn: token == null ? false : true,
    userId: token !== null ? decodeUserDetails(token as string)._id : null,
    role: token !== null ? decodeUserDetails(token as string).role : 'user',
  })),
  on(setGlobalEmail, (state, { email }) => ({
    ...state,
    email: email,
  })),
  on(setUserState, (state, { userToken }) => ({
    ...state,
    ...userToken,
  }))
);
/**
 * gets globalReducer
 * @param state global state
 * @param action global action
 * @returns reducer state and action
 */
export const userReducer = (state: UserToken | undefined, action: Action) => {
  return reducer(state, action);
};
