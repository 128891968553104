import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _storage!: Storage;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    // Create or load storage
    const storage = await this.storage.create();
    this._storage = storage;
  }

  // Set a value in storage
  set(key: string, value: any) {
    if (key && value) {
      return from(this._storage?.set(key, value));
    } else {
      return;
    }
  }

  // Get a value from storage
  get(key: string): Observable<any> {
    return from(this._storage?.get(key));
  }

  // Remove a value from storage
  remove(key: string): Observable<void> {
    return from(this._storage?.remove(key));
  }

  // Clear the entire storage
  clear(): Observable<void> {
    return from(this._storage?.clear());
  }
}
