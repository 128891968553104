import { createReducer, on } from '@ngrx/store';
import {
  resetReg,
  setEmail,
  setEmailWrongOtp,
  setLoginState,
  setMobileWrongOtp,
  setPhone,
  setRegStep,
  setRegToken,
} from '../actions/home-page.action';
import { RegistrationSteps } from '../../helper/registration.steps.enum';

export interface RegState {
  currentStep: string;
  email: string | null;
  phone: string | null;
  token: string | null;
  emailWrongOTP: boolean;
  mobileWrongOTP: boolean;
  userLogin: boolean;
}

const initialState: RegState = {
  currentStep: RegistrationSteps.ACCOUNT_CREATION,
  email: null,
  phone: null,
  token: null,
  emailWrongOTP: false,
  mobileWrongOTP: false,
  userLogin: false,
};

export const regStoreReducer = createReducer(
  initialState,
  on(setEmail, (state, { email }) => ({
    ...state,
    email: email,
  })),
  on(setPhone, (state, { phone }) => ({
    ...state,
    phone: phone,
  })),
  on(setRegToken, (state, { token }) => ({
    ...state,
    token: token,
  })),
  on(setRegStep, (state, { currentStep }) => ({
    ...state,
    currentStep: currentStep,
  })),
  on(setEmailWrongOtp, (state, { emailWrongOTP }) => ({
    ...state,
    emailWrongOTP: emailWrongOTP,
  })),
  on(setMobileWrongOtp, (state, { mobileWrongOTP }) => ({
    ...state,
    mobileWrongOTP: mobileWrongOTP,
  })),
  on(setLoginState, (state, { userLogin }) => ({
    ...state,
    userLogin: userLogin,
  })),
  on(resetReg, (state) => ({
    ...state,
    ...initialState,
  }))
);
