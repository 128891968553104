import { createAction, props } from '@ngrx/store';
import { UserToken } from '../reducer/user.reducer';

/**
 * sets screen size
 */
export const setUser = createAction(
  '[Global] Set User',
  props<{ token: string | null }>()
);
/**
 * sets screen size
 */
export const setGlobalEmail = createAction(
  '[Global] Set EMAIL User',
  props<{ email: string }>()
);

export const setUserState = createAction(
  '[Global] Set User',
  props<{ userToken: UserToken }>()
);
export const setkycUserInfo = createAction(
  '[Global] Set User Info',
  props<{ kycUserInfo: any }>()
);
