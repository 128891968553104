import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  GlobalState,
  _selectGlobalState,
  globalUserEmail,
  isLoggedIn,
} from './store/selectors/user.selector';
import { Storage } from '@ionic/storage-angular';
import { setGlobalEmail, setUser } from './store/action/user.action';
import { NavigationEnd, Router } from '@angular/router';
import { getUserInfo } from './store/action/kyc.action';
import { LogOutService } from './logout.service';
import { IonSelect } from '@ionic/angular';
import { Location } from "@angular/common";
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [LogOutService],
})
export class AppComponent implements OnInit {
  $isLoggedIn: Observable<boolean> = this.store.select(isLoggedIn);
  $email: Observable<string> = this.store.select(globalUserEmail);
  @ViewChild('mySelect', { static: false }) selectRef!: IonSelect;
  user: any;
  email: string = '';
  token!: string;
  currentUrl: string = '';
  constructor(
    private store: Store,
    private storage: Storage,
    private change: ChangeDetectorRef,
    private router: Router,
    private logOutService: LogOutService,
    private location: Location
  ) {
    defineCustomElements(window);
    this.initializeApp();
    this.storage?.get('state').then((state) => {
      if (state) {
        if (state.isLoggedIn && state.token.length) {
          this.store.dispatch(setUser({ token: state.token }));
          this.store.dispatch(setGlobalEmail({ email: state.email }));
          this.token = state.token;
        }
      }
    });
    this.store.select(GlobalState).subscribe((data) => {
      if (data) {
        if (data.isLoggedIn && data.token?.length)
          this.storage.set('state', data).then(() => { });
      }
    });

    this.store.select(globalUserEmail).subscribe((email) => {
      if (email) {
        this.email = email;
        this.change.detectChanges();
      }
    });
    window.addEventListener('unload', () => {
      this.store.dispatch(getUserInfo({ token: this.token }));
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
}

  async initializeApp() {
  await this.storage.create().then();
}

LogOut() {
  this.logOutService.openLogoutModal();
}

navTOReg() {
  this.router.navigateByUrl('/register/account');
}

navTOLogin() {
  // go back
  this.location.back();
}

openSelect() {
  this.selectRef.open();
}
}
