import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class KYCService {
  baseUrl = environment.BASE_URL;

  constructor(private httpClient: HttpClient) { }

  appendUserInfo(form: any, token: string, additionalParams?: any) {
    let headers = new HttpHeaders();
    headers = headers.set('authorization', `${token}`);
    if (additionalParams && additionalParams.isAdmin) {
      return this.httpClient.post(this.baseUrl + '/user/update?userId=' + additionalParams.userId, form, {
        headers,
      });
    } else {
      return this.httpClient.post(this.baseUrl + '/user/update', form, {
        headers,
      });
    }
  }

  updateSavedUserInfo(form: any, token: string, additionalParams?: any) {
    let headers = new HttpHeaders();
    headers = headers.set('authorization', `${token}`);
    if (additionalParams && additionalParams.isAdmin) {
      return this.httpClient.post(this.baseUrl + '/user/update?userId=' + additionalParams.userId, form, {
        headers,
      });
    } else {
      return this.httpClient.patch(this.baseUrl + '/user/update/previous', form, {
        headers,
      });
    }
  }

  getInfo(token: string, additionalParams?: any) {
    let headers = new HttpHeaders();
    headers = headers.set('authorization', `${token}`);
    if (additionalParams && additionalParams.isAdmin) {
      return this.httpClient.get<{
        status: boolean;
        code: number;
        data: any;
      }>(this.baseUrl + '/user/get/info?userId=' + additionalParams.userId, {
        headers,
      });
    } else {
      return this.httpClient.get<{
        status: boolean;
        code: number;
        data: any;
      }>(this.baseUrl + '/user/get/info', {
        headers,
      });
    }
  }

  getDropDown(type: string, token: string) {
    let headers = new HttpHeaders();
    headers = headers.set('authorization', `${token}`);
    const params = new HttpParams().set('riskType', type);

    return this.httpClient.get<{
      status: boolean;
      code: number;
      data: any;
    }>(this.baseUrl + '/risk/rating/score/get/type', {
      headers,
      params,
    });
  }

  companyDetailsGetAllDropdowns(token: string) {
    const countryOfIncorporation: Observable<{
      status: boolean;
      code: number;
      data: {
        _id: string;
        countryOfIncorporation: string;
        riskLevelForCountryOfIncorporation: string;
        score: number;
        type: string;
      }[];
    }> = this.getDropDown('countryOfIncorporation', token);
    const countryOfOperation: Observable<{
      status: boolean;
      code: number;
      data: {
        _id: string;
        countryOfOperation: string;
        riskLevelForCountryOfOperation: string;
        score: number;
        type: string;
      }[];
    }> = this.getDropDown('countryOfOperation', token);
    const legalRegulatoryBody: Observable<{
      status: boolean;
      code: number;
      data: {
        _id: string;
        regulatoryCounty: string;
        primaryRegulatoryCounty: string;
        riskPrimaryRegulatoryBody: string;
        score: number;
        type: string;
      }[];
    }> = this.getDropDown('legalRegulatoryBody', token);
    const legalStructure: Observable<{
      status: boolean;
      code: number;
      data: {
        _id: string;
        legalStructure: string;
        riskLegalStructure: string;
        score: number;
        type: string;
      }[];
    }> = this.getDropDown('legalStructure', token);
    const lengthOfEstablishment: Observable<{
      status: boolean;
      code: number;
      data: {
        _id: string;
        howOld: string;
        lengthOfEstablishment: string;
        score: number;
        type: string;
      }[];
    }> = this.getDropDown('lengthOfEstablishment', token);
    const typeOfBusinessActivity: Observable<{
      status: boolean;
      code: number;
      data: {
        _id: string;
        value: string;
        type: string;
      }[];
    }> = this.getDropDown('typeOfBusinessActivity', token);

    return forkJoin([
      countryOfIncorporation,
      countryOfOperation,
      legalRegulatoryBody,
      legalStructure,
      lengthOfEstablishment,
      typeOfBusinessActivity,
    ]);
  }

  getPresignedFile(key: string, token: string) {
    let headers = new HttpHeaders();
    headers = headers.set('authorization', `${token}`);
    const params = new HttpParams().set('key', key);

    return this.httpClient.get<{
      status: boolean;
      code: number;
      data: any;
    }>(this.baseUrl + '/document/get/presigned', {
      headers,
      params,
    });
  }

  allCountries(token: string): Observable<any> {
    return this.getDropDown('cityAndCountry', token);
  }
}
