import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createAction, select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { RegisterService } from '../../register.service';
import {
  adminLogin,
  regEmail,
  regVerifyEmailOtp,
  resendOtpEmail,
  resetReg,
  resetUser,
  sentPhoneOtp,
  setEmail,
  setEmailWrongOtp,
  setLoginState,
  setMobileWrongOtp,
  setRegStep,
  setRegToken,
  updateNewPassword,
  userLogin,
  verifyPhoneOtp,
} from '../actions/home-page.action';
import { RegistrationSteps } from '../../helper/registration.steps.enum';
import { getRegEmail, getRegToken } from '../selectors/home-page.selector';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/shared/toast.service';
import { setGlobalEmail, setUser } from 'src/app/store/action/user.action';
import { getUserInfo } from 'src/app/store/action/kyc.action';
import { decodeUserDetails } from 'src/app/helpers/auth.helpers';

export const noAction = createAction('[Campaigns] No Action ');
@Injectable({
  providedIn: 'root',
})
export class RegStoreEffects {
  regEmail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(regEmail),
        mergeMap((data) => {
          this.registerService.createUser(data).subscribe({
            next: (res: any) => {
              if (res.code === 200) {
                this.store.dispatch(setRegToken({ token: res.data.token }));
                this.store.dispatch(setEmail({ email: data.email }));
                this.store.dispatch(
                  setRegStep({
                    currentStep: RegistrationSteps.EMAIL_VERIFICATION,
                  })
                );
              }
            },
            error: (err: any) => {
              if (err.code == 400) {
              }
              return of(noAction());
            },
          });
          return of(noAction());
        })
      ),
    { dispatch: false }
  );
  verifyEmailOTP$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(regVerifyEmailOtp),
        withLatestFrom(this.store.pipe(select(getRegToken))),
        switchMap(([data, token]) => {
          this.registerService.emailOTPVerify(data, token as string).subscribe({
            next: (res: any) => {
              if (res.status == false && res.code === 200) {
                this.store.dispatch(setEmailWrongOtp({ emailWrongOTP: true }));
                return;
              } else if (
                res.code === 200 &&
                res.data.emailVerification == 'COMPLETED'
              ) {
                if (!data.forgotPassword) {
                  this.store.dispatch(
                    setEmailWrongOtp({ emailWrongOTP: false })
                  );
                  this.store.dispatch(
                    setRegStep({
                      currentStep: RegistrationSteps.PHONE_VERIFICATION,
                    })
                  );
                } else {
                  this.router.navigateByUrl('/auth/new-password');
                }
              }
            },
            error: (err: any) => {
              if (err.code == 400) {
                // this.store.dispatch(
                //   setEmailWrongOtp({ emailWrongOTP: true })
                // );
              }
              return of(noAction());
            },
          });
          return of(noAction());
        })
      ),
    { dispatch: false }
  );
  sentPhoneOTP$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sentPhoneOtp),
        withLatestFrom(this.store.pipe(select(getRegToken))),
        switchMap(([data, token]) => {
          this.registerService
            .sentPhoneOTP(data.contactNumber, token as string)
            .subscribe({
              next: (res: any) => {
                if (res.status === false && res.code === 200) {
                  this.toastService.presentToast(
                    'bottom',
                    res.message,
                    'error'
                  );
                }
              },
              error: (err: any) => {
                if (err.code == 400) {
                }
                return of(noAction());
              },
            });
          return of(noAction());
        })
      ),
    { dispatch: false }
  );
  verifyPhoneOTP$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(verifyPhoneOtp),
        withLatestFrom(this.store.pipe(select(getRegToken))),
        switchMap(([data, token]) => {
          this.registerService
            .verifyPhoneOTP(data.otp, token as string)
            .subscribe({
              next: (res: any) => {
                if (res.code == 400) {
                  this.toastService.presentToast(
                    'bottom',
                    res.message,
                    'error'
                  );
                  return;
                }

                if (res.code == 200 && res.status == false) {
                  this.store.dispatch(
                    setMobileWrongOtp({ mobileWrongOTP: true })
                  );

                  return;
                }

                if (
                  res.code === 200 &&
                  res.data.phoneVerification == 'COMPLETED'
                ) {
                  this.store.dispatch(
                    setMobileWrongOtp({ mobileWrongOTP: false })
                  );

                  this.store.dispatch(setRegToken({ token: res.data.token }));
                  this.store.dispatch(setLoginState({ userLogin: true }));
                  this.store.dispatch(resetReg());
                  this.router.navigate(['register/reg-sucess']);
                }
              },
              error: (err: any) => {
                if (err.code == 400) {
                }
                return of(noAction());
              },
            });
          return of(noAction());
        })
      ),
    { dispatch: false }
  );
  resendOtpEmail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(resendOtpEmail),
        withLatestFrom(this.store.pipe(select(getRegToken))),
        switchMap(([data, token]) => {
          this.registerService.resendEmailOTPVerify(token as string).subscribe({
            next: (res: any) => {
              if (res.code === 200 && res.data.phoneOtpTriggered) {
                this.store.dispatch(setRegToken({ token: res.data.token }));
                // this.store.dispatch(
                //   setRegStep({
                //     currentStep: RegistrationSteps.PHONE_VERIFICATION,
                //   })
                // );
              }
            },
            error: (err: any) => {
              if (err.code == 400) {
              }
              return of(noAction());
            },
          });
          return of(noAction());
        })
      ),
    { dispatch: false }
  );
  resetUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(resetUser),
        mergeMap((data) => {
          this.registerService.resetUser(data.email).subscribe({
            next: (res: any) => {
              if (res.data.token) {
                this.toastService.presentToast(
                  'bottom',
                  'Email Sent !',
                  'error'
                );
                this.store.dispatch(setRegToken({ token: res.data.token }));
                this.router.navigate(['/auth/verify-password']);
              }
            },
            error: (err: any) => {
              if (err) {
                this.toastService.presentToast('bottom', 'Error', 'error');
              }
              return of(noAction());
            },
          });
          return of(noAction());
        })
      ),
    { dispatch: false }
  );
  updateNewPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateNewPassword),
        withLatestFrom(this.store.pipe(select(getRegToken))),

        mergeMap(([data, token]) => {
          this.registerService
            .updateNewPassword(data.password, token as string)
            .subscribe({
              next: (res: any) => {
                if (res?.data?.accountReset) {
                  this.toastService.presentToast(
                    'bottom',
                    'Password Reset!',
                    'success',
                    'mail-outline'
                  );
                  this.router.navigate(['/']);
                } else {
                  this.toastService.presentToast('bottom', 'Error', 'error');
                  this.router.navigate(['/']);
                }
              },
              error: (err: any) => {
                if (err) {
                  this.toastService.presentToast('bottom', 'Error', 'error');
                }
                return of(noAction());
              },
            });
          return of(noAction());
        })
      ),
    { dispatch: false }
  );
  userLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userLogin),
        mergeMap((data) =>
          this.registerService.userLogin(data).pipe(
            tap((res: any) => {
              if (res.code == 400) {
                this.toastService.presentToast('bottom', res.message, 'error');
                return;
              }

              if (res.code === 200) {
                this.store.dispatch(resetReg());

                this.store.dispatch(setRegToken({ token: res.data.token }));
                this.store.dispatch(getUserInfo({ token: res.data.token }));
                this.store.dispatch(setEmail({ email: data.email }));
                this.store.dispatch(setGlobalEmail({ email: data.email }));

                if (
                  res.data.emailVerification === 'PENDING' &&
                  res.data.phoneVerification === 'PENDING'
                ) {
                  this.router.navigate(['register/account']);
                  this.store.dispatch(
                    setRegStep({
                      currentStep: RegistrationSteps.EMAIL_VERIFICATION,
                    })
                  );
                } else if (
                  res.data.emailVerification === 'COMPLETED' &&
                  res.data.phoneVerification === 'PENDING'
                ) {
                  this.router.navigate(['register/account']);
                  this.store.dispatch(
                    setRegStep({
                      currentStep: RegistrationSteps.PHONE_VERIFICATION,
                    })
                  );
                } else if (
                  res.data.emailVerification === 'COMPLETED' &&
                  res.data.phoneVerification === 'COMPLETED'
                ) {
                  this.store.dispatch(setLoginState({ userLogin: true }));
                  this.router.navigate(['register/no-kyc']);
                } else if (
                  res.data.token &&
                  decodeUserDetails(res.data.token).role == 'admin'
                ) {
                  this.store.dispatch(setLoginState({ userLogin: true }));
                  this.router.navigate(['admin/list']);
                }
              }
            }),
            catchError((err: any) => {
              this.toastService.presentToast('bottom', err.message, 'error');
              return of(noAction());
            })
          )
        )
      ),
    { dispatch: false }
  );

  adminLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(adminLogin),
        mergeMap((data) =>
          this.registerService.adminLogin(data).pipe(
            tap((res: any) => {
              if (res.code == 400) {
                this.toastService.presentToast('bottom', res.message, 'error');
                return;
              }

              if (res.code === 200) {
                this.store.dispatch(resetReg());

                this.store.dispatch(setRegToken({ token: res.data.token }));
                this.store.dispatch(getUserInfo({ token: res.data.token }));
                this.store.dispatch(setEmail({ email: data.email }));
                this.store.dispatch(setGlobalEmail({ email: data.email }));

                if (
                  res.data.emailVerification === 'PENDING' &&
                  res.data.phoneVerification === 'PENDING'
                ) {
                  this.router.navigate(['register/account']);
                  this.store.dispatch(
                    setRegStep({
                      currentStep: RegistrationSteps.EMAIL_VERIFICATION,
                    })
                  );
                } else if (
                  res.data.emailVerification === 'COMPLETED' &&
                  res.data.phoneVerification === 'PENDING'
                ) {
                  this.router.navigate(['register/account']);
                  this.store.dispatch(
                    setRegStep({
                      currentStep: RegistrationSteps.PHONE_VERIFICATION,
                    })
                  );
                } else if (
                  res.data.emailVerification === 'COMPLETED' &&
                  res.data.phoneVerification === 'COMPLETED'
                ) {
                  this.store.dispatch(setLoginState({ userLogin: true }));
                  this.router.navigate(['register/no-kyc']);
                } else if (
                  res.data.token &&
                  decodeUserDetails(res.data.token).role == 'admin'
                ) {
                  this.store.dispatch(setLoginState({ userLogin: true }));
                  this.router.navigate(['admin/list']);
                }
              }
            }),
            catchError((err: any) => {
              this.toastService.presentToast('bottom', err.message, 'error');
              return of(noAction());
            })
          )
        )
      ),
    { dispatch: false }
  );
  userLoginState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setLoginState),
        withLatestFrom(
          this.store.pipe(select(getRegToken)),
          this.store.pipe(select(getRegEmail))
        ),
        switchMap(([login, token, email]) => {
          if (login.userLogin) {
            this.store.dispatch(setUser({ token }));
            this.store.dispatch(setGlobalEmail({ email: email as string }));
          }
          return of(noAction());
        })
      ),
    { dispatch: false }
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private registerService: RegisterService,
    private router: Router,
    private toastService: ToastService
  ) {}
}
